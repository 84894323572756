
              @import "@/assets/css/variables/variables.scss";
            

.verification-code-container {
  display: flex;
  .verification-code {
    @include rtl-sass-prop(margin-right, margin-left, 1rem);
  }
}
.err-code {
  color: #e91d2c;
}
.remind-email {
  color: #0051ff;
}
@media (max-width: 550px) {
  .verification-code-container {
    flex-direction: column;
    text-align: center;
  }
}
