#forceChangePassword {
    background-color: #f6f9fc;
    background-size: cover;
    .content_box {
      min-height: calc(100vh - 157px) !important;
      padding-top: 120px;
  
      h2 {
        color: $primary-purple;
      }
    }
  }
  
  .forceChangePassword_box {
    max-width: 943px;
    margin: 0 auto;
    padding: 83px 0 98px;
    background-color: #fff;
    box-shadow: $box-shadow;
  
    .reset_form {
      max-width: 500px;
      margin: 0 auto;
  
      .el-form-item {
        &:not(:first-child) {
          margin-top: 80px;
        }
      }
  
      .btn_item {
        padding-top: 20px;
        text-align: center;
  
        .el-button {
          width: 125px;
          border-radius: 23px;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    #forceChangePassword .content_box {
      min-height: calc(100vh - 71px - 140px) !important;
      padding-top: 130px;
    }
  }
  
  @media (max-width: 550px) {
    .forceChangePassword {
      padding: 45px 24px 24px;
    }
  }
  